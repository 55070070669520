.testimonials{
    /* background-color: #3b4f40; */
    padding: 0;
    text-align: center;
    border-radius: 0.5rem;
    margin-left: 1rem;
    /* height: 50vh; */
}

.tests{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
}
.testimonial{
    background-color: #3b4f40;
    border-radius: 0.5rem;
}

.testimonial image{
    width: 80%;
    /* height: 40%; */
    border-radius: 0.5rem;
}
.testimonial img{
    width: 60%;
    height: 40vh;
    border-radius: 50%;
}

.testimonial h5{
    font-size: 1.5rem;
    margin: 0.5rem 0 0.5rem 0;
    /* background-color: darkgrey; */
}

.testimonial p{
    font-size: 1.2rem;
    margin: 0.5rem 0 0.5rem 0;
}
.testimonial p button{
    height: 2.5rem;
    width: 8rem;
    font: 1.5rem bold sans-serif;
    border-radius: 1rem;
    margin:0 0 0 68%;
}

@media screen and (max-width: 768px){
    .testimonials{
        margin-top: 2rem;
    }
    
    .tests{
        grid-template-columns: 1fr 1fr;
    }
}