.congrats{
    width: 100%;
    height: 100%;
    text-align: center;
}
.congrats-text{
    background-color: #495E57;
    text-align: center;
    border-radius: 0.5rem;
}

@keyframes thanks-run{
    0%{
      transform: translateX(100%);
    }
    100%{
      transform: translateX(-100%);
    }
  }
.congrats-text h2{
    color: #F4CE14;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    margin-left: 10px;
    border-radius: 1.5rem;
    animation: thanks-run 20s ease-in-out infinite;
}
.mssg{
    display: flex;
    flex-direction: row;
    background-color: #495E57;
    align-items: center;
    justify-content: center;
}
.mssg .img{
    width: 40%;
    height: 100%;
    /* background-color: red; */
}
.mssg img{
    /* width: 30%; */
}
.img img{
    width: 100%;
    height: 20rem;
    border-radius: 0.5rem;
}
.mssg .word{
    width: 60%;
}


.mssg h1{
    font-size: 4rem;
    margin: 0;
    padding: 0;
    color:#F4CE14
}
.mssg p{
    font:3rem bold sans-serif;
    color:#F4CE14;
}

.bye{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.bye p{
    width: 20%;
    padding: 2rem;
    margin: 2rem;
    font-size: 2.5rem;
    font-weight: bold;
    color: #F4CE14;
    text-align: center;
    background-color: green;
    border-radius: 1rem;
}
.bye button{
    padding: 1rem;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: bold;
    color: #F4CE14;
    text-align: center;
    background-color: #495E57;
    border-radius: 1rem;
    border: none;
    cursor: pointer;
}
.bye button:hover{
    background-color: #2e7821;
    color: white;
    cursor: pointer;
}

@media  screen and (max-width: 768px) {
    .mssg{
        flex-direction: column;
    }
    .mssg .img{
        width: 100%;
        height: 100%;
    }
    .mssg .word{
        width: 100%;
    }
    .mssg h1{
        font-size: 3rem;
    }
    .mssg p{
        font-size: 2rem;
    }
    .bye p{
        width: 50%;
        padding: 1rem;
        margin: 1rem;
        font-size: 2rem;
    }
    .bye button{
        padding: 0.5rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }
    .bye button:hover{
        background-color: #2e7821;
        color: white;
        cursor: pointer;
    }
    .congrats-text h2{
        font-size: 1.5rem;
    }
}