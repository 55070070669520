.booking{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
form{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}


.form-item{
    width:80%;
    height: 30px;
    display: flex;
    flex-direction: column;
    margin: 5% 3%;
}
.form-item input{
    border-radius: 5px;
    padding: 1.3rem;
    font-size: 1.5rem;
}
.form-item select{
    width: 100%;
    border-radius: 5px;
    margin: 0 0 0 0;
    padding: 0.5rem;
    font-size: 1.5rem;
}


.form-item label{
    width: 400px;
    font: 1.6rem solid black;
}
.form-other{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    font: 1.5rem solid black;
    width: 100%;
}
.form-other label{
    text-align: left;
}
.form-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    font: 1.5rem solid black;
    margin: 0 0 0 -3.5rem;
}
.form-text label{
    margin: 0rem 0 0rem -10rem;
    font: 1.5rem solid black;
}
.form-other textarea{
    width: 100%;
    height: 4rem;
    font-size: 1.5rem;
    text-overflow: hidden;
}
 .booking-btn{
    border: 0.1rem solid black;
    text-align: center;
    padding: 1rem;
    font-size: 2rem;
    background-color: rgb(13, 173, 173);
    cursor: pointer;
    position: absolute;
    bottom: -7rem;
    left: 30%;
    border-radius: 1.5rem;
}
button:hover{
    background-color: green;
}


.form-item .norm{
   padding: 0.5rem;
}


@media screen and (max-width:768px){
    .details{
        display: grid;
        grid-template-columns: 1fr;
    }
    .form-other textarea{
        width: 255px;
    }
    .form-other button{
        width: 250px;
    }
    .form-other{
        margin: 2rem 0 0 -1rem;
    }
}
