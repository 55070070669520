.add-menu{
    display:flex;
    flex-direction: column;
    align-items: center;

}
form{
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 1rem;
    margin-top: 5%;
    /* background-color:blue; */

}
.menu-item{
    display: flex;
    flex-direction: column;
    font: 1.5rem bold solid black;
}
.menu-item input{
    width: 80%;
    height: 2.5rem;
    font-size: 1.5rem;
    border-radius: 0.5rem;
}
.menu-item input[type='file']{
    /* padding: 1rem 0 1rem 0; */
    border-radius: 0.5rem;
    font-size: 1.3rem;

}
.menu-item textarea{
    width: 80%;
    height: 3rem;
    border-radius: 0.5rem;
    font-size: 1.5rem;
}

form input[type=submit]{
    font-size: 1.5rem;
    width: 10rem;
    height: 3rem;
    border-radius: 0.5rem;
    background-color: lightgreen;
    margin:20% 0 0 70%;
}