/* #landing-page-container {
    margin: 0, 5%;
} */

.navigate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: black;
}

.logo {
    display: flex;
    flex-direction: row;
    color: wheat;
}
.logo img {
    width: auto;
    height: 3rem;
}
.llinks {
    display: flex;
    flex-direction: row;
    columns: white;
}

.llinks li {
    list-style: none;
    padding: 1rem;
    margin: 0 0.5rem;
    border-radius: 0.8rem;
    /* background-color: green; */
    font: 1.5rem solid white;
    font-weight: bolder;
    color: white;
    border: 0.2rem solid wheat;
}
.llinks li a {
    color: white;
    text-decoration: none;
}
.llinks li:hover {
    background-color: transparent;
    cursor: pointer;
    color: wheat;
}
.intro {
    margin-top: 5rem;
    background-color: #00000078;
    text-align: center;
    color: wheat;
    font: 1.5rem solid bold;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
}

.intro-text {
    opacity: 1;
    width: 80%;
}
.intro-text p {
    font-size: 1.9rem;
}

.lheading {
    width: 100%;
    height: 35rem;
    background-image: url("../images/banner.png");
    background-size: cover;
    background-color: black;
}

.body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 0rem;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 1rem 0;
}

.body div {
    width: 95%;
    height: 30rem;
    background-color: lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    margin: 2%;
}
.body div p {
    margin: 3%;
}

.body div img {
    width: 70%;
    height: 80%;
}

.about {
    background-color: lightblue;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.text1 {
    margin: 2rem;
}

.about .text{
    margin:0 0 20rem 0;
    background-color:green ;
    width:60%;
}
.about video{
    width: 30%;
    height:auto;
}